import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import faviconcolor from './faviconcolor.png';
import Home from './Components/Home/Home.tsx';
import Notreapproche from './Components/Notreapproche/Notreapproche.tsx';
import Champsdapplication from './Components/Champsdapplication/Champsdapplication.tsx';
import Notreequipe from './Components/Notreequipe/Notreequipe.tsx';
import Whyteleconsultation from './Components/Whyteleconsultation/Whyteleconsultation.tsx';
import Tarifs from './Components/Tarifs/Tarifs.tsx';
import Contact from './Components/Contact/Contact.tsx';

function App() {
  const homeRef = useRef(null);
  const approachRef = useRef(null);
  const fieldsRef = useRef(null);
  const teamRef = useRef(null);
  const whyTeleconsultationRef = useRef(null);
  const tarifsRef = useRef(null);
  const contactRef = useRef(null);
  const navbarHeight = 60;
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const dropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - navbarHeight,
      behavior: 'smooth',
    });
  };

  const handleSetActiveSection = (ref) => {
    scrollToRef(ref);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLinkClick = (ref) => {
    handleSetActiveSection(ref);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logique pour traiter le formulaire
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    setIsDesktop(mediaQuery.matches);

    const handleResize = () => {
      setIsDesktop(mediaQuery.matches);
    };

    mediaQuery.addListener(handleResize);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      <nav className="navbar">
        <img src={faviconcolor} alt="Logo" />
        <button onClick={() => handleSetActiveSection(homeRef)}>Accueil</button>
        <button onClick={() => handleSetActiveSection(approachRef)}>Notre approche</button>
        <button onClick={() => handleSetActiveSection(fieldsRef)}>Champs d'application</button>
        <button onClick={() => handleSetActiveSection(teamRef)}>Notre équipe</button>
        <button onClick={() => handleSetActiveSection(whyTeleconsultationRef)}>Pourquoi la téléconsultation ?</button>
        <button onClick={() => handleSetActiveSection(tarifsRef)}>Tarifs</button>
        <button onClick={() => handleSetActiveSection(contactRef)}>Contact</button>
        <button className="button-appointment" onClick={() => window.location.href = 'tel:0489786685'}>Prendre rendez-vous</button>
      </nav>

      {!isDesktop && (
        <button className="round-button" onClick={toggleDropdown}>
          ☰
        </button>
      )}

      {showDropdown && (
        <div className="dropdown" id="dropdown" ref={dropdownRef}>
          <ul>
            <li><a onClick={() => handleLinkClick(homeRef)}>Accueil</a></li>
            <li><a onClick={() => handleLinkClick(approachRef)}>Notre approche</a></li>
            <li><a onClick={() => handleLinkClick(fieldsRef)}>Champs d'application</a></li>
            <li><a onClick={() => handleLinkClick(teamRef)}>Notre équipe</a></li>
            <li><a onClick={() => handleLinkClick(whyTeleconsultationRef)}>Pourquoi la téléconsultation ?</a></li>
            <li><a onClick={() => handleLinkClick(tarifsRef)}>Tarifs</a></li>
            <li><a onClick={() => handleLinkClick(contactRef)}>Contact</a></li>
          </ul>
        </div>
      )}

      <div className="sections">
        <div ref={homeRef} className="section">
          <Home />
        </div>
        <div ref={approachRef} className="section approach-section">
          <Notreapproche />
        </div>
        <div id="champs-application" ref={fieldsRef} className="section fields-section">
          <Champsdapplication />
        </div>
        <div className="section">
          <div ref={teamRef}>
            <Notreequipe />
          </div>
        </div>
        <div ref={whyTeleconsultationRef} className="section">
          <Whyteleconsultation />
        </div>
        <div ref={tarifsRef} className="section">
          <Tarifs />
        </div>
        <div ref={contactRef} className="section">
          <Contact handleSubmit={handleSubmit} />
        </div>
      </div>
      <a onClick={openModal} className="h33">
        Conditions générales
      </a>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="h4">Politique de confidentialité</h3>
              <button className="modal-close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h4>Protection des données personnelles :</h4>
              <p>
                Au cours de la navigation sur le site, l’utilisateur pourra être
                amené à communiquer des informations le concernant. Ces informations,
                à caractère personnel, sont utilisées pour prendre contact et échanger
                avec les services internes de l'entreprise CTTB s’engage
                ce que la collecte et le traitement des données, effectuées à partir du site
                <a href="https://www.cttbfrance.com/"> https://www.cttbfrance.com/</a>,
                soient conformes au règlement général sur la protection des données (RGPD)
                et à la loi Informatique et Libertés.
              </p>

              <h4>a. Collecte de données anonymes :</h4>
              <p>
                Aucune information personnelle relative à l’identité de l’utilisateur n’est
                collectée à son insu. Néanmoins, pendant la consultation du site, certaines
                données de l’utilisateur sont recueillies automatiquement :
              </p>
              <ul>
                <li>
                  L’adresse IP (cette adresse permettant d’accéder à Internet est affectée à
                  l’ordinateur de l’utilisateur par son fournisseur d’accès à Internet et
                  cette information, qui change en fonction de la session de l’utilisateur,
                  n’est pas personnellement identifiable) ;
                </li>
                <li>
                  L’adresse du site Internet à partir de laquelle l’utilisateur est directement
                  relié au site <a href="https://www.cttbfrance.com/">https://www.cttbfrance.com/</a>.
                </li>
                <li>
                  La date et l’heure de la visite de l’utilisateur sur le site Internet
                  <a href="https://www.cttbfrance.com/"> https://www.cttbfrance.com/</a> ainsi que
                  les pages consultées ;
                </li>
                <li>
                  Le système d’exploitation de l’ordinateur de l’utilisateur ainsi que son
                  logiciel de navigation.
                </li>
              </ul>
              <p>
                Par conséquent, l'entreprise CTTB recueille sur ce site des
                données anonymes des utilisateurs (ou données anonymes), telles que les pages
                visitées et les recherches entreprises. Ces données ne sont pas nominatives car
                elles ne permettent en aucun cas d’identifier personnellement l’utilisateur du
                site. L'entreprise CTTB utilise ces informations anonymes afin
                d’améliorer le contenu de son site et d’obtenir des statistiques globales sur
                les consultations.
              </p>

              <h4>b. Collecte de données nominatives :</h4>
              <p>
                Afin d’accéder à certaines fonctionnalité et certains services, pour contacter et
                échanger avec les services internes, l’utilisateur pourra fournir des informations
                personnelles telles que son nom, son prénom, son adresse email, adresse postale,
                son numéro de téléphone. Ces informations sont collectées à l’aide de formulaires
                à compléter volontairement par l’utilisateur. L'entreprise CTTB
                ne divulguera pas à des tiers les données personnelles transmise par les utilisateurs
                via les formulaires à compléter ou la messagerie automatique. Elles ne seront utilisées
                que dans le but d’échanger le plus efficacement possible. Lorsque l’utilisateur adresse
                un message électronique au site <a href="https://www.cttbfrance.com/"> https://www.cttbfrance.com/</a>,
                il peut être amené à communiquer certaines données nominatives. Dans ce cas, ces données
                nominatives sont traitées et conservées pendant une durée qui n’excède pas 5 ans, aux seules
                fins de communication avec l’utilisateur. Aucune donnée n’est destinée à des tiers sans
                autorisation préalable de l’utilisateur.
              </p>

              <h4>c. Sécurité :</h4>
              <p>
                Pour protéger les informations personnelles que vous communiquez, l'entreprise CTTB
                utilise toutes les techniques de sécurisation à sa disposition en l’état actuel des avancées technologiques.
                L’Internet demeure néanmoins un réseau ouvert, de sorte que l'entreprise CTTB ne peut
                garantir une sécurité totale, du fait, notamment de tiers malveillant.
              </p>

              <h4>d. Droit d’accès :</h4>
              <p>
                L’utilisateur dispose d’un droit d’accès, de modification, de rectification et de suppression des données
                le concernant. Pour l’exercice de ces droits, l'utilisateur doit envoyer une demande via le formulaire relatif
                aux données personnelles.
              </p>

              <h4>e. Cookies et témoins connexion :</h4>
              <p>
                Nous informons l’utilisateur que, lors de sa visite sur notre site, celui-ci peut envoyer sur son ordinateur
                un certain nombre de données qui seront stockées sur son disque dur, dans un fichier appelé « cookie ». Un cookie
                est un élément qui sert à enregistrer des informations relatives à la navigation de l’utilisateur sur le site Internet.
                Son navigateur contient une fonction permettant de refuser les cookies (toutes précisions utiles sur le site de la CNIL).
                Le fait de refuser les cookies n’empêchera pas la connexion à notre site mais pourra en limiter les fonctionnalités.
              </p>
              <p>
                Le cas échéant, l’utilisateur peut refuser l’insertion de ces cookies en suivant la procédure indiquée sur son navigateur
                (voir ci-après la démarche à suivre), selon les modalités proposées lui convenant le mieux.
              </p>
              <p>Ce choix est modifiable à tout moment.</p>
              <p>
                Des plug-ins sociaux ou boutons de partage sur les réseaux sociaux sont susceptibles, d’être présents sur notre site,
                pour permettre d’accéder à la fonctionnalité de partage de contenus avec d’autres personnes sur les réseaux sociaux depuis
                notre site ou de faire connaître à ces autres personnes sa consultation ou son opinion concernant un contenu de notre site
                (plug-ins sociaux « j’aime », « partager », …).
              </p>
              <p>
                Du seul fait de leur présence, ces plug-ins sociaux, que l’utilisateur soit également ou non utilisateur de plateformes sociales,
                sont susceptibles de déposer des cookies sur son disque dur pour tracer sa navigation et identifier ses centres d’intérêts.
                L’émission et l’utilisation de cookies par ces tiers sont soumises à leurs propres politiques de protection de la vie privée.
                Nous invitons l’utilisateur à en prendre connaissance afin d’identifier les finalités d’utilisation, notamment publicitaires,
                et les informations de navigation qu’ils peuvent recueillir grâce aux plug-ins applicatifs et ainsi fixer son choix. Ces politiques
                de protection doivent notamment permettre à l’utilisateur d’exercer son choix auprès de ces réseaux sociaux, notamment en paramétrant
                ses comptes d’utilisation de chacun de ces réseaux.
              </p>
              <p>
                Par ailleurs, pour analyser les préférences de l’utilisateur et, sous réserve de son consentement, des pixels invisibles ou balises
                internet sont susceptibles d’être placés par un prestataire tiers sur certain de nos articles et messages pour nous aider à identifier
                les pages consultées, comptabiliser les fréquences d’affichage ainsi que le nombre de visiteurs, analyser les structures du trafic et
                établir des statistiques pour les contenus les plus appréciés.
              </p>
              <p>
                Enfin, des enregistrements audio et video, des podcasts, des webcasts sont à disposition par l’intermédiaire de services tiers. Lors d’accès
                éventuels et sous réserve de son consentement, ces tiers sont susceptibles de déposer des cookies sur le disque dur de l’utilisateur pour nous
                aider à identifier les contenus les plus appréciés.
              </p>

              <h4>La démarche à suivre pour refuser l’insertion de ces cookies « tiers » est la suivante, selon le navigateur utilisé :</h4>

              <h4>Pour Mozilla firefox :</h4>
              <ol>
                <li>Choisissez le menu « outil » puis « options »</li>
                <li>Cliquez sur l’icône « vie privée »</li>
                <li>Repérez le menu « cookie » et sélectionnez les options qui vous conviennent</li>
                <li><a href="http://support.mozilla.org/fr/kb/activer-desactiver-cookies?redirectlocale=fr&amp;redirectslug=Activer+et+d%C3%A9sactiver+les+cookies">http://support.mozilla.org/fr/kb/activer-desactiver-cookies?redirectlocale=fr&amp;redirectslug=Activer+et+d%C3%A9sactiver+les+cookies</a></li>
              </ol>

              <h4>Pour Microsoft Internet Explorer :</h4>
              <ol>
                <li>Choisissez le menu « outil » puis « options Internet »</li>
                <li>Cliquez sur l’icône « Confidentialité »</li>
                <li>Sélectionnez le niveau souhaité à l’aide du curseur</li>
                <li><a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a></li>
              </ol>

              <h4>Pour Chrome :</h4>
              <ol>
                <li>Allez dans le menu Chrome / Préférences</li>
                <li>Cliquez sur « Afficher les paramètres avancés »</li>
                <li>Cliquez sur « Paramètres de contenu… » dans la partie confidentialité</li>
                <li>Cliquez sur « Cookies et données de site… » dans la nouvelle fenêtre</li>
                <li>Cliquez sur « Tout supprimer » et validez en cliquant sur « OK »</li>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en</a></li>
              </ol>

              <h4>Pour Safari :</h4>
              <ol>
                <li>Allez dans le menu Safari / Préférences</li>
                <li>Cliquez sur « confidentialité »</li>
                <li>Dans « Bloquer les cookies », cochez « Toujours »</li>
                <li><a href="http://www.apple.com/support/?path=Safari/3.0/fr/9277.html">http://www.apple.com/support/?path=Safari/3.0/fr/9277.html</a></li>
              </ol>

              <h4>
                Pour connaître les options offertes par tout autre logiciel de navigation et les modalités de suppression de fichiers cookies stockés dans votre terminal, la Cnil détaille sur ce lien les démarches à suivre pour limiter vos traces sur le web <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/conseils-aux-internautes/">http://www.cnil.fr/vos-droits/vos-traces/les-cookies/conseils-aux-internautes/</a> et, plus généralement, vous informe sur les mécanismes de paramétrage des cookies <a href="https://www.cnil.fr/fr/maitriser-mes-donnees">https://www.cnil.fr/fr/maitriser-mes-donnees</a>.
              </h4>
              <p>
                En poursuivant la navigation, l’utilisateur consent à l’utilisation de ces cookies « tiers » sur notre site.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
