import React, { useState } from 'react';
import './Notreapproche.css'; // Vous pouvez créer et utiliser ce fichier pour ajouter des styles spécifiques

const Notreapproche: React.FC = () => {
  const [showFullText, setShowFullText] = useState({
    approach: false,
    force: false,
  });

  const toggleText = (section: 'approach' | 'force') => {
    setShowFullText((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div>
      <div>
        <h1>La co-thérapie</h1>
        <p>
          Dans le cadre d’une co-thérapie, chaque suivi est étudié et analysé par l’ensemble de l’équipe dans le but d’assurer une coordination efficace et une meilleure compréhension des besoins de chaque personne. Avec une approche basée sur la sophrologie, l’écoute, la régulation émotionnelle, l’hypnose, la thérapie cognitive et comportementale, la programmation neuro linguistique, la relaxation,… nous mettons tout à disposition pour vous apporter le changement désiré.
        </p>
        {showFullText.approach ? (
          <>
            <p>
              <br/>
              Entre chacune de vos séances d'accompagnement, nous restons présents pour vous. En appelant notre centre vous serez mis en relation avec l'un de nos thérapeutes pour une écoute attentive et bienveillante. Nous pourrons également vous proposer des outils sophrologiques simples, adaptés à vos besoins, pour vous aider à vous sentir mieux entre vos rendez-vous. Votre bien-être est notre priorité, et nous sommes là pour vous soutenir à chaque étape de votre accompagnement.
            </p>
            <button className="btn-voir-plus" onClick={() => toggleText('approach')}>Voir moins</button>
          </>
        ) : (
          <button className="btn-voir-plus" onClick={() => toggleText('approach')}>Voir plus</button>
        )}
      </div>

      <div>
        <h1>Notre force</h1>
        <p>
          Le partage de nos observations et de nos évaluations individuelles permet une meilleure vision de la personne accompagnée au CTTB. Une assurance que tous les membres de l'équipe travaillent ensemble dans l’intérêt de la  personne accompagnée afin qu’elle atteigne son objectif.
        </p>
        {showFullText.force ? (
          <>
            <p>
              <br/>
              Planifications régulières de réunions d’équipe afin d’évaluer les progrès de chaque personne accompagnée, et si nécessaire, la proposition d’intervention d’un second thérapeute sur une ou deux séances, afin de renforcer le suivi et d'apporter un soutien complémentaire.
            </p>
            <button className="btn-voir-plus" onClick={() => toggleText('force')}>Voir moins</button>
          </>
        ) : (
          <button className="btn-voir-plus" onClick={() => toggleText('force')}>Voir plus</button>
        )}
      </div>
    </div>
  );
};

export default Notreapproche;
