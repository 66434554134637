import React from 'react';
import './Whyteleconsultation.css'; // Vous pouvez créer et utiliser ce fichier pour ajouter des styles spécifiques

const Whyteleconsultation: React.FC = () => {
  return (
    <div>
      <h1>Pourquoi la téléconsultation ?</h1>
      <br />
      <p className="h4">En choisissant la téléconsultation, vous optez pour une approche moderne et flexible qui s'adapte à votre mode de vie tout en vous offrant un accompagnement personnalisé et professionnel.</p>
      <br />
      <div className="consultation-card">
        <h2>Confort et praticité</h2>
        <p>La téléconsultation vous permet de bénéficier de nos services depuis le confort de votre foyer, évitant ainsi les déplacements et les contraintes du temps. Vous pouvez ainsi participer à vos séances de sophrologie et de relaxation où que vous soyez, tant que vous disposez d'une connexion internet.</p>
      </div>

      <div className="consultation-card">
        <h2>Flexibilité horaire</h2>
        <p>Les consultations en ligne offrent une flexibilité et une accessibilité accrues. Vous pouvez choisir des créneaux qui s'adaptent à votre emploi du temps, que ce soit en journée, en soirée.</p>
      </div>

      <div className="consultation-card">
        <h2>Accessibilité</h2>
        <p>La téléconsultation élimine les barrières géographiques. Que vous habitiez en ville ou en zone rurale, vous pouvez avoir accès à nos services sans contrainte de distance. Cela permet également à ceux qui ont des difficultés de mobilité de bénéficier de nos accompagnements.</p>
      </div>

      <div className="consultation-card">
        <h2>Confidentialité</h2>
        <p>Les consultations en ligne garantissent la confidentialité de nos échanges. Vous pouvez vous sentir à l'aise pour partager vos préoccupations et explorer vos émotions dans un environnement sécurisé et intime.</p>
      </div>

      <div className="consultation-card">
        <h2>Continuité de l’accompagnement</h2>
        <p>Pas de contraintes de déplacement, la téléconsultation assure la continuité de votre suivi thérapeutique. Vous pouvez maintenir votre parcours de bien-être sans interruption, ce qui contribue à une progression régulière vers vos objectifs.</p>
      </div>
    </div>
  );
};

export default Whyteleconsultation;
