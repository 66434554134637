import React from 'react';
import './Tarifs.css'; // Vous pouvez créer et utiliser ce fichier pour ajouter des styles spécifiques
import icontarifs from '../../icontarif.png';


const Tarifs: React.FC = () => {
  return (
    <div>
      <h1 className="section-title">Tarifs</h1>
      <div className="tariff-content">
        <div className="tariff-details">
          <p>Séance d’accompagnement individuel adultes adolescents :</p>
          <p><strong>60€/Séance</strong></p>
          <p><strong>50€/Séance pour les étudiants</strong></p>
          <p>Entreprises, associations (nous consulter)</p>
          <hr />
          <br/>
          <p>Moyens de paiement acceptés :</p>
          <p>Paylib, Paypal</p>
          <hr />
          <br/>
          <p>Plateformes :</p>
          <p>Skype, GoogleMeet, whatsapp...</p>
          <br/>
          <img src={icontarifs} alt="logo et illustration" className="image-container2 small" />
          <br/>
          <br/>
          <br/>
          <p>Un grand nombre de mutuelles remboursent les séances de sophrologie, renseignez-vous auprès de votre complémentaire santé.</p>
        </div>
        <br/>
        <div className="consultation-button-container">
        <a href="https://www.chambre-syndicale-sophrologie.fr/mutuelle-sophrologie-sophrologue/" className="button" target="_blank" style={{ textDecoration: 'none' }}>Je me renseigne</a>
        </div>
        <br/>
        <div className="consultation-tips">
          <br/>
          <br/>
            <div className="tariff-tips-content">
              <p><strong>Pour une consultation dans de bonnes conditions et nous permettre de poser un cadre professionnel, vous avez besoin :</strong></p>
              <ul>
                <li>- D’un espace calme où vous ne serez pas dérangé(e)</li>
                <li>- D’une connexion internet (idéalement en Wi-Fi ou en filaire plutôt qu’en 3G ou 4G qui sont plus instables)</li>
                <li>- D’une caméra et micro qui fonctionnent (votre smartphone ou ordinateur pour la caméra et micro de votre smartphone, ordinateur ou casque)</li>
              </ul>
            </div>

        </div>
        <br />
      </div>
    </div>
  );
};

export default Tarifs;
