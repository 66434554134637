import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    telephone: '',
    mail: '',
    postal: '', 
    ville: '',
    problematique: '',
    message: '',
    comment: ''
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    emailjs.sendForm('service_n4vrdvn', 'template_d52lypj', e.currentTarget, 'JUSZVErtGL-5zlS-h')
      .then((result) => {
        console.log(result.text);
        setFormSubmitted(true);
      }, (error) => {
        console.log(error.text);
      });
  };

  const problematiques = ["Pathologies mentales", "Les troubles du sommeil", "Autour de la femme", "Autres"];

  return (
    <>
      <h1 className="section-title">Contact</h1>
      <p className="intro2 consultation-card">
        Pour une prise de rendez-vous, veuillez remplir le formulaire ci-dessous ou nous contacter par téléphone.
      </p>
      <br />
      <button className="phone-button" onClick={() => window.location.href = 'tel:0489786685'}>
        Prendre rendez-vous
      </button>
      <br />
      {formSubmitted ? (
        <p className='h3'>Merci pour votre message. Nous vous recontacterons dans les 48 heures.</p>
      ) : (
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="nom">Nom :</label>
              <input type="text" id="nom" name="nom" value={formData.nom} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="prenom">Prénom :</label>
              <input type="text" id="prenom" name="prenom" value={formData.prenom} onChange={handleChange} required />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="telephone">Numéro de téléphone :</label>
              <input type="tel" id="telephone" name="telephone" value={formData.telephone} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="mail">Mail :</label>
              <input type="email" id="mail" name="mail" value={formData.mail} onChange={handleChange} required />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
            <label htmlFor="postal">Région :</label>
              <select id="postal" name="postal" value={formData.postal} onChange={handleChange} required>
                <option value="">Sélectionnez votre région</option>
                <option value="Auvergne-Rhône-Alpes">Auvergne-Rhône-Alpes</option>
                <option value="Bourgogne-Franche-Comté">Bourgogne-Franche-Comté</option>
                <option value="Bretagne">Bretagne</option>
                <option value="Centre-Val de Loire">Centre-Val de Loire</option>
                <option value="Corse">Corse</option>
                <option value="Grand Est">Grand Est</option>
                <option value="Hauts-de-France">Hauts-de-France</option>
                <option value="Île-de-France">Île-de-France</option>
                <option value="Normandie">Normandie</option>
                <option value="Nouvelle-Aquitaine">Nouvelle-Aquitaine</option>
                <option value="Occitanie">Occitanie</option>
                <option value="Pays de la Loire">Pays de la Loire</option>
                <option value="Provence-Alpes-Côte d'Azur">Provence-Alpes-Côte d'Azur</option>
              </select>
            </div>
            
            <div className="form-group">
              <label htmlFor="ville">Ville :</label>
              <input type="text" id="ville" name="ville" value={formData.ville} onChange={handleChange} required />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="problematique">Problématique :</label>
            <select id="problematique" name="problematique" value={formData.problematique} onChange={handleChange} required>
              <option value="">Sélectionnez une problématique</option>
              {problematiques.map((problematique, index) => (
                <option key={index} value={problematique}>{problematique}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="message">Message (Optionnel) :</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="comment">Comment avez-vous connu notre Centre de Téléconsultation en Thérapie Brève ?</label>
            <input type="text" id="comment" name="comment" value={formData.comment} onChange={handleChange} />
          </div>

          <button type="submit">Envoyer</button>
        </form>
      )}
    </>
  );
};

export default Contact;
